import React from 'react';
import { NavLink } from 'react-router-dom';
import whiteLogo from '../assets/logo-white.png';
import { BlackButton } from './black-button';
import './website-footer.css';

export const WebsiteFooter = () => {
  return (
    <>
      <section className="bg-greyBg web_padding_x py-14 md:py-24 tall:py-32 2xl:py-32 ">
        <h2 className="web_heading text-center font-finoSans mx-auto">
          JOIN OUR COMMUNITY
        </h2>
        <p className="web_paragraph_1 text-center md:text-lg mt-3 max-w-[380px] mx-auto">
          Sign up to be the first to hear about our limited-edition collection,
          exclusive products.
        </p>

        <div className="mt-5 w-[80%] max-w-[410px] mx-auto flex gap-2 flex-col relative">
          <input
            type="email"
            className="text-sm h-10 px-[21px] border-black border-solid border outline-none rounded-md sm:rounded-[21px]"
            placeholder="Enter Your Email"
          />
          <BlackButton
            text="Subscribe"
            className="text-sm h-10 px-[21px] rounded-md sm:rounded-[21px] sm:absolute sm:right-0 sm:top-0"
          />
        </div>
      </section>

      <footer className="bg-black Footer text-white px-6 sm:web_padding_x web_padding_y">
        <div className="grid gap-6 md:gap-x-20 xl:gap-x-28 2xl:gap-[10%] md:grid-cols-[auto_1fr]">
          <img src={whiteLogo} className="w-[200px]" alt="" />

          <div className="grid gap-6 lg:gap-24 2xl:gap-0 md:grid-cols-[auto_auto_auto] md:items-start lg:grid-cols-[auto_auto_auto_1fr] 2xl:grid-cols-[20%_20%_20%_1fr]">
            <div className="grid gap-3 md:gap-5 lg:gap-10">
              <h3 className="font-bold text-lg">COMPANY</h3>
              <NavLink to="about">About Us</NavLink>
              <NavLink to="how-it-works">How It Works</NavLink>
              <NavLink to="contact-us">Contact Us</NavLink>
            </div>
            <div className="grid gap-3 md:gap-5 lg:gap-10">
              <h3 className="font-bold text-lg">LEGAL</h3>
              <NavLink to="privacy">Privacy Policy</NavLink>
              <NavLink to="terms-of-use">Terms Of Use</NavLink>
            </div>
            <div className="grid gap-3 md:gap-5 lg:gap-10">
              <h3 className="font-bold text-lg">HELP</h3>
              <NavLink to="faqs">FAQS</NavLink>
              <NavLink to="policies">POLICIES</NavLink>
            </div>
          </div>
        </div>

        <p className="web_padding_t text-sm">
          Copyright {new Date().getFullYear()} Faith Grey. All Rights Reserved
        </p>
      </footer>
    </>
  );
};
