import React from "react";
import { BlackButton } from "../../components/black-button";
import { ItemCountInput } from "../../components/item-count-input";

export const Cart = () => {
  return (
    <>
      <main className="web_padding_x web_padding_y max-w-[2000px]">
        <h2 className="web_heading bar_above">Cart</h2>

        <div className="grid mt-8 gap-7 xl:grid-cols-[65%_35%] items-start">
          <div className="rounded-[20px] border border-solid border-[#EBEBEB] p-8">
            <div className="font-bold text-lg">( 4 items )</div>

            <div className="mt-10">
              <div className="flex flex-col md:flex-row gap-2 md:gap-5 items-center">
                <div className="w-[132px] h-[196px] rounded-[27px] bg-[#EBEBEB]"></div>
                <h4 className="font-bold text-xl md:self-start">Title Name</h4>
                <div className="flex-1 flex flex-col justify-around md:flex-row gap-2 md:gap-5 items-center md:items-end">
                  <button className="whitespace-nowrap outline-none underline hover:font-semibold active:opacity-[0.7]">
                    Remove
                  </button>
                  <button className="whitespace-nowrap outline-none underline hover:font-semibold active:opacity-[0.7]">
                    Save for later
                  </button>
                  <div className="md:flex-1 max-w-[200px]">
                    <div className="text-center md:text-right font-bold text-[30px] mb-3 md:mb-6 sm:text-[37px]">
                      ₵ 21.33
                    </div>
                    <ItemCountInput
                      value={0}
                      onValueChange={() => {}}
                      min={0}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rounded-[20px] border border-solid border-[#EBEBEB] pt-8 pb-6">
            <BlackButton
              text="Proceed to checkout"
              className="block mx-auto"
            ></BlackButton>

            <div className="flex justify-between pt-4 pb-2 px-6 md:px-12 font-bold">
              <div>Subtotal (2items)</div>
              <div>₵ 21.33</div>
            </div>
            <div className="flex justify-between border-t border-t-[#EBEBEB] border-solid pt-4 pb-2 px-6 md:px-12 font-bold">
              <div>Taxes</div>
              <div>Calculated & Checkout</div>
            </div>
            <div className="mx-3 h-[1px] bg-[#EBEBEB] mt-2"></div>
            <div className="flex justify-between pt-4 pb-2 px-6 md:px-12 font-bold">
              <div>Estimated Total</div>
              <div>₵ 21.33</div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
