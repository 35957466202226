import React, { useEffect, useRef, useState } from "react";
import { FaCamera } from "react-icons/fa";
import hamburger from "../../assets/icons/black-hamburger.svg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import {
  RadioGroup,
  Radio,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Select from "react-select";
import { BlackButton } from "../../components/black-button";
import axios from "axios";
import { environment } from "../../environments/environment";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api/api";
import { toast } from "react-toastify";
import moment from "moment";
import { useForm } from "react-hook-form";

export const AdminNewCampaign = () => {
  const { uuid } = useParams(); //in case of viewing single campaign to edit
  const navigate = useNavigate();
  const [imageUrls, setImageUrls] = useState<string | null>(null);
  const [selectedSizeType, setSizeType] = useState<any>(null);
  const [selectedCategories, setCategories] = useState<
    { label: string; value: string }[]
  >([]);
  const [isLocationExclusive, setIsLocationExclusive] = useState<"yes" | "no">(
    "no"
  );

  const [size, setSize] = useState<any>([]);
  const [size_ml, setSize_Ml] = useState<any>("");
  const [size_kg, setSize_Kg] = useState<any>("");
  const [size_hwl, setSize_Hwl] = useState<any>("");
  const [size_men_shoe, setSize_Men] = useState<any>([]);
  const [size_women_shoe, setSize_Women] = useState<any>([]);

  const [updateImage, setUpdateImage] = useState<any>("");
  const [updatesField, setUpdatesField] = useState<string>("");

  const [loading, setLoading] = useState(false);

  const [categoriesList, setCategoriesList] = useState<
    { label: string; value: string }[]
  >([]);

  const {
    register,
    formState: { errors, isValid },
    setValue,
    handleSubmit,
  } = useForm();

  const submitMe = async (values: any) => {
    if (
      Number(values.production_goal_order_number) >
      Number(values.end_goal_order_number)
    ) {
      return toast.error(
        "Production Goal Order Number should be less than End Goal Order Number"
      );
    }
    setLoading(true);
    let payload = { ...values };
    payload.image_url = imageUrls;
    payload.production_goal_price = Number(values.production_goal_price);
    payload.end_goal_order_number = Number(values.end_goal_order_number);
    payload.end_goal_price = Number(values.end_goal_price);
    payload.minimum_quantity = Number(values.minimum_quantity);
    payload.production_goal_order_number = Number(
      values.production_goal_order_number
    );
    payload.location_exclusive = isLocationExclusive;

    if (selectedSizeType === "size") {
      payload.size = size.map((x: any) => x.value);
    } else if (selectedSizeType === "ml") {
      payload.size_ml = size_ml;
    } else if (selectedSizeType === "kg") {
      payload.size_kg = size_kg;
    } else if (selectedSizeType === "h_w_l") {
      payload.size_hwl = "";
    } else if (selectedSizeType === "men") {
      payload.size_men_shoe = size_men_shoe.map((x: any) => x.value);
    } else if (selectedSizeType === "women") {
      payload.size_women_shoe = size_women_shoe.map((x: any) => x.value);
    }

    if (uuid) {
      //when editting
      try {
        payload.campaign_uuid = uuid;

        if (selectedCategories[0]?.value) {
          payload.category = selectedCategories[0]?.value;
        }

        const response = await api.put(
          `${environment.baseUrl}/admin/campaign/edit`,
          payload
        );

        if (response.data.success) {
          toast.success(response.data.message);
          navigate("/admin/campaigns");
        } else {
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    } else {
      //when creating
      try {
        payload.category = selectedCategories[0].value;

        const response = await api.post(
          `${environment.baseUrl}/admin/campaign/create`,
          payload
        );

        if (response.data.success) {
          toast.success(response.data.message);
          navigate("/admin/campaigns");
        } else {
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  };

  const handleImageUpload = (event: any) => {
    const files = event.target.files;

    handleImage_s(files);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleImage_s(files);
  };

  const handleImage_s = (file_s: any) => {
    // Handle image upload here
    if (file_s && file_s[0]) {
      for (const file of file_s) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", "cqom5ijc");

        axios
          .post(
            `https://api.cloudinary.com/v1_1/dlicyptzz/image/upload`,
            formData
          )
          .then((response) => {
            setImageUrls((prevImageUrls) => {
              if (prevImageUrls) {
                return prevImageUrls + "," + response.data.secure_url;
              } else {
                return response.data.secure_url;
              }
            });
          })
          .catch((error) => {
            console.error("Error uploading image:", error);
          });
      }
    }
  };

  const handleUpdateImage = (event: any) => {
    const files = event.target.files;
    // Handle image upload here
    if (files && files[0]) {
      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("upload_preset", "cqom5ijc");

      axios
        .post(
          `https://api.cloudinary.com/v1_1/dlicyptzz/image/upload`,
          formData
        )
        .then((response) => {
          setUpdateImage(response.data.secure_url);
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
        });
    }
  };

  const fetchCategoriesList = async () => {
    try {
      const response = await api.get(`${environment.baseUrl}/admin/categories`);

      if (response.data.code == 200) {
        setCategoriesList(
          response.data.data.all_categories.map((x: any) => ({
            label: x.name,
            value: x.short_name,
          }))
        );
        if (uuid) {
          fetchCampaignDetails();
        }
      }
    } catch (error) {
      navigate("/campaigns");
    }
  };

  const fetchCampaignDetails = async () => {
    try {
      const response = await api.get(
        `${environment.baseUrl}/campaign/fetch/single/${uuid}`
      );

      if (response.data.success) {
        const data_ = response.data.data.campaign;
        setImageUrls(data_.image_url);
        setValue("campaign_title", data_.campaign_title);
        setValue("product_title", data_.product_title);
        setValue("production_goal_price", data_.production_goal_price);
        setValue(
          "production_goal_order_number",
          data_.production_goal_order_number
        );
        setValue("end_goal_price", data_.end_goal_price);
        setValue("end_goal_order_number", data_.end_goal_order_number);
        setValue("campaign_deadline", data_.campaign_deadline.split("T")[0]);
        setValue("location", data_.location);
        setValue("campaign_description", data_.campaign_description);
        setValue("minimum_quantity", data_.minimum_quantity);
        setValue("campaign_updates", data_.campaign_updates);
        setCategories([
          categoriesList.find((x) => x.value === data_.category)!,
        ]);
        setIsLocationExclusive(data_.location_exclusive);

        if (data_.size) {
          setSizeType("size");
          setSize(data_.size);
        } else if (data_.size_ml) {
          setSizeType("ml");
          setSize_Ml(data_.size_ml);
        } else if (data_.size_kg) {
          setSizeType("kg");
          setSize_Kg(data_.size_kg);
        } else if (data_.size_h_w_l) {
          setSizeType("hwl");
          setSize_Hwl(data_.size_h_w_l);
        } else if (data_.size_men_show) {
          setSizeType("men");
          setSize_Men(data_.size_men_shoe);
        } else if (data_.size_women_show) {
          setSizeType("women");
          setSize_Women(data_.size_women_shoe);
        }
      }
    } catch (error) {
      navigate("/admin/campaigns");
    }
  };

  const submitUpdate = async () => {
    try {
      const response = await api.post(
        `${environment.baseUrl}/admin/campaign-update`,
        {
          text: updatesField,
          image_url: updateImage,
          campaign_uuid: uuid,
        }
      );

      if (response.data.success) {
        toast.success(response.data.message);
        setUpdatesField("");
        setUpdateImage(null);
      } else {
      }
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    fetchCategoriesList();
  }, []);

  // const [campaignDetails, setCampaignDetails] = useState<any>(initialValues);

  return (
    <section className="font-montserrat">
      <div className="flex items-center gap-3">
        <img src={hamburger} alt="campaigns-icon" className="w-[18px]" />
        <h5 className="text-[22px] font-bold">Campaigns</h5>
      </div>

      <form
        onSubmit={handleSubmit(submitMe)}
        className="mt-7 bg-white gap-y-5 gap-x-[5%] text-black p-8 tall:py-[60px] xl:px-9 rounded-[18px] grid items-start xl:grid-cols-2"
      >
        <div className="grid gap-y-5 items-start">
          <div className="flex flex-col gap-5 w-full">
            <h6 className="font-bold text-sm md:text-base">CAMPAIGN TITLE</h6>

            <TextField
              className="admin_input_1"
              placeholder="Campaign title Name...."
              {...register("campaign_title", { required: true })}
            />
          </div>

          <div className=" flex flex-col gap-5 w-full">
            <h6 className="font-bold text-sm md:text-base">PRODUCT TITLE</h6>

            <TextField
              className="admin_input_1"
              placeholder="Product title Name...."
              {...register("product_title", { required: true })}
            />
          </div>

          <div className=" flex flex-col gap-5 w-full">
            <h6 className="font-bold text-sm md:text-base">
              PRODUCTION GOAL PRICE
            </h6>

            <TextField
              className="admin_input_1"
              placeholder="123456"
              type="number"
              {...register("production_goal_price", { required: true })}
            />
          </div>

          <div className=" flex flex-col gap-5 w-full">
            <h6 className="font-bold text-sm md:text-base">
              PRODUCTION GOAL ORDER NUMBER
            </h6>
            <TextField
              className="admin_input_1"
              placeholder="123456"
              type="number"
              {...register("production_goal_order_number", { required: true })}
            />
          </div>

          <div className=" flex flex-col gap-5 w-full">
            <h6 className="font-bold text-sm md:text-base">END GOAL PRICE</h6>

            <TextField
              className="admin_input_1"
              placeholder="123456"
              type="number"
              {...register("end_goal_price", { required: true })}
            />
          </div>

          <div className=" flex flex-col gap-5 w-full">
            <h6 className="font-bold text-sm md:text-base">
              END GOAL ORDER NUMBER
            </h6>

            <TextField
              className="admin_input_1"
              placeholder="123456"
              type="number"
              {...register("end_goal_order_number", { required: true })}
            />
          </div>

          <div className=" flex flex-col gap-5 w-full">
            <h6 className="font-bold text-sm md:text-base">MINIMUM QUANTITY</h6>

            <TextField
              className="admin_input_1"
              placeholder="123456"
              type="number"
              {...register("minimum_quantity", { required: true })}
            />
          </div>

          <h6 className="font-bold text-sm md:text-base">
            Select one or multiple categories
          </h6>

          <Select
            isMulti={true}
            options={categoriesList}
            value={selectedCategories}
            onChange={(e: any) => setCategories(e)}
            placeholder="Select one or multiple categories"
          />

          <h6 className="font-bold text-sm md:text-base">SIZE</h6>
          <Accordion
            expanded={true}
            className="!rounded-[10px] [border:1px_solid_#CCCCCC_!important] !m-0 [box-shadow:none_!important]"
          >
            <AccordionSummary
              className="!px-6"
              expandIcon={<div className="expand-icon-accordion"></div>}
              id="panel1a-header"
            >
              Select size
            </AccordionSummary>
            <AccordionDetails>
              <RadioGroup className="grid gap-4">
                <div className="flex items-center">
                  <Radio
                    onClick={() => setSizeType("ml")}
                    checked={selectedSizeType === "ml"}
                    value="ml"
                  />
                  <div className="flex items-center gap-3">
                    <TextField
                      className="admin_size_input"
                      onClick={() => setSizeType("ml")}
                      value={size_ml}
                      onChange={(e) => setSize_Ml(e.target.value)}
                    />
                    <span className="text-xs">ml</span>
                  </div>
                </div>
                <div className="flex items-center">
                  <Radio
                    onClick={() => setSizeType("kg")}
                    checked={selectedSizeType === "kg"}
                    value="kg"
                  />
                  <div className="flex items-center gap-3">
                    <TextField
                      className="admin_size_input"
                      onClick={() => setSizeType("kg")}
                      value={size_kg}
                      onChange={(e) => setSize_Kg(e.target.value)}
                    />
                    <span className="text-xs">KGs</span>
                  </div>
                </div>
                <div className="flex items-center">
                  <Radio
                    onClick={() => setSizeType("hwl")}
                    checked={selectedSizeType === "hwl"}
                    value="hwl"
                  />
                  <div className="flex items-center gap-3">
                    <TextField
                      className="admin_size_input"
                      onClick={() => setSizeType("hwl")}
                      value={size_hwl}
                      onChange={(e) => setSize_Hwl(e.target.value)}
                    />
                    <span className="text-xs">HxWxL</span>
                  </div>
                </div>
                <div className="flex items-center">
                  <Radio
                    onClick={() => setSizeType("size")}
                    checked={selectedSizeType === "size"}
                    value="size"
                  />
                  <div className="flex items-center gap-3">
                    <Select
                      isMulti={true}
                      onMenuOpen={() => setSizeType("size")}
                      options={[
                        { value: "S", label: "Small" },
                        { value: "M", label: "Medium" },
                        { value: "L", label: "Large" },
                      ]}
                      value={size}
                      onChange={(e) => {
                        setSize(e);
                        console.log(size);
                      }}
                      placeholder="Select an option"
                    />
                    <span className="text-xs">S,M,L,XL, XXL</span>
                  </div>
                </div>
                <div className="flex items-center">
                  <Radio
                    onClick={() => setSizeType("men")}
                    checked={selectedSizeType === "men"}
                    value="men"
                  />
                  <div className="flex items-center gap-3">
                    <Select
                      isMulti={true}
                      onMenuOpen={() => setSizeType("men")}
                      options={[
                        { value: 7, label: 7 },
                        { value: 8, label: 8 },
                        { value: 9, label: 9 },
                      ]}
                      value={size_men_shoe}
                      onChange={(e) => setSize_Men(e)}
                      placeholder="Select an option"
                    />
                    <span className="text-xs">Men's Shoe</span>
                  </div>
                </div>
                <div className="flex items-center">
                  <Radio
                    onClick={() => setSizeType("women")}
                    checked={selectedSizeType === "women"}
                    value="women"
                  />
                  <div className="flex items-center gap-3">
                    <Select
                      isMulti={true}
                      onMenuOpen={() => setSizeType("women")}
                      options={[
                        { value: 7, label: 7 },
                        { value: 8, label: 8 },
                        { value: 9, label: 9 },
                      ]}
                      value={size_women_shoe}
                      onChange={(e) => setSize_Women(e)}
                      placeholder="Select an option"
                    />
                    <span className="text-xs">Women's Shoe</span>
                  </div>
                </div>
              </RadioGroup>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="grid gap-y-5 items-start">
          <div className=" flex flex-col gap-5 w-full">
            <h6 className="font-bold text-sm md:text-base">END DATE</h6>

            <TextField
              min={moment().add(1, "day").format("YYYY-MM-DD")}
              type="date"
              className="admin_input_1"
              {...register("campaign_deadline", { required: true })}
            />
          </div>
          <div className=" flex flex-col gap-5 w-full">
            <h6 className="font-bold text-sm md:text-base">ADD LOCATION</h6>

            <TextField
              className="admin_input_1"
              placeholder="Accra, Ghana"
              {...register("location", { required: true })}
            />
            <FormControlLabel
              checked={isLocationExclusive === "yes"}
              onChange={() =>
                setIsLocationExclusive((prev) =>
                  prev === "yes" ? "no" : "yes"
                )
              }
              control={<Checkbox />}
              label="Is Location Excluxive"
            />
          </div>

          <div className=" flex flex-col gap-5 w-full">
            <h6 className="font-bold text-sm md:text-base">CURRENCY</h6>

            <RadioGroup defaultValue="GHS">
              <FormControlLabel
                value="GHS"
                control={<Radio {...register("currency")} />}
                label="GHS"
              />
              <FormControlLabel
                value="NGN"
                control={<Radio {...register("currency")} />}
                label="NGN"
              />
              <FormControlLabel
                value="ZAR"
                control={<Radio {...register("currency")} />}
                label="ZAR"
              />
            </RadioGroup>
          </div>

          <div className=" flex flex-col gap-5 w-full">
            <h6 className="font-bold text-sm md:text-base">CAMPAIGN IMAGE</h6>
            <div className="grid grid-cols-2 gap-4">
              {imageUrls?.split(",").map((image, index) => {
                // console.log(imageUrls);
                return <img key={index} src={image} alt="Campaign Image" />;
              })}
              <input
                id="image-upload"
                type="file"
                accept="image/*"
                multiple={true}
                onChange={handleImageUpload}
              />
            </div>
          </div>

          <div className="flex flex-col gap-5 w-full">
            <h6 className="font-bold text-sm md:text-base">
              CAMPAIGN DESCRIPTION
            </h6>
            <div className="relative grid">
              <TextField
                multiline
                rows={6}
                className="admin_input_1 admin_textarea"
                {...register("campaign_description", { required: true })}
              />
              <button
                type="button"
                className="bg-black absolute bottom-6 right-4 text-white flex items-center gap-2 py-2 px-4 rounded-md  focus:outline-none focus:shadow-outline transform transition duration-300 perspective-origin-center hover:-rotate-y-6 hover:scale-[1.01] active:rotate-y-180 active:scale-100"
              >
                <FaCamera />
                <span>Add Photos</span>
              </button>
            </div>
          </div>

          <BlackButton
            text={uuid ? "Edit Campaign" : "Submit"}
            type="submit"
            isDisabled={!isValid || !imageUrls}
            className="max-w-[200px]"
            isLoading={loading}
          />

          {uuid && (
            <div className="flex flex-col gap-5 w-full border-t border-black pt-5 mt-5">
              <h6 className="font-bold text-sm md:text-base">
                ADD CAMPAIGN UPDATES
              </h6>
              {updateImage && (
                <div>
                  <img src={updateImage} alt="" />
                </div>
              )}
              <div className="flex gap-3 md:gap-5 w-full">
                <label
                  htmlFor="updateFileInput"
                  className="bg-black text-white cursor-pointer h-[30px] md:h-[44px] aspect-square rounded-full flex justify-center items-center"
                >
                  <FaCamera className="text-xs sm:text-base" />
                </label>
                <input
                  type="file"
                  id="updateFileInput"
                  onChange={handleUpdateImage}
                  className="hidden"
                />
                <TextField
                  multiline
                  rows={6}
                  value={updatesField}
                  onChange={(e) => setUpdatesField(e.target.value)}
                  className="admin_input_1 flex-1"
                />
              </div>
              <div className="mx-auto">
                <button
                  type="button"
                  onClick={() => {
                    submitUpdate();
                  }}
                  className="bg-black text-white py-2 px-4 rounded-md  focus:outline-none focus:shadow-outline transform transition duration-300 perspective-origin-center hover:-rotate-y-6 hover:scale-[1.01] active:rotate-y-180 active:scale-100"
                >
                  Post Campaign Update
                </button>
              </div>
            </div>
          )}
        </div>
      </form>
    </section>
  );
};
