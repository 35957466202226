import aboutImg from "../../assets/images/about.png";

export const About = () => {
  return (
    <main className="about_layout">
      <div className="about_layout_content lg:mt-[-20px]">
        <h2 className="web_heading bar_above ">About us</h2>
        <p className="web_paragraph_1 mt-2 ">
          Faith Grey is a social commerce platform for the continent of Africa.
          We know the cost of daily household goods are an important concern for
          consumers looking to provide for themselves and loved ones.
        </p>
        <p className="web_paragraph_1 mt-2 ">
          The market shopping experience leaves consumers bargaining on goods
          that fluctuate in price at any given time. No simplified way exists to
          partner together using social apps with others to increase the
          consumer&apos;s purchasing power on everyday household items within
          the continent of Africa today. We utilize social apps to help
          consumers partner together to get everyday household items at great
          prices.
        </p>
        <p className="web_paragraph_1 mt-2 ">
          Our goal is to increase the purchasing power of the people within the
          continent while creating disposable income for our consumers.
        </p>
      </div>

      <img src={aboutImg} alt="Faith Grey Water Bottle" />
    </main>
  );
};
