import React from "react";
import axios from "axios";
import { environment } from "../../../environments/environment";
import { NavLink, useNavigate } from "react-router-dom";
import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import { WebsiteFooter } from "../../../components/website-footer";
import logo from "../../../assets/logo.png";
import { BlackButton } from "../../../components/black-button";
import { ILoginVerify } from "../../../models/admin/auth.types";
import OTPInput from "react-otp-input";
import { toast } from "react-toastify";
import api from "../../../api/api";

export const VerifyOtp = () => {
  const otpValidationSchema = Yup.object({
    uuid: Yup.string().required(),
    code: Yup.string().length(5).required(),
  });

  const otpFormValues: ILoginVerify = {
    uuid: localStorage.getItem("uuid_to_verify")!,
    code: "",
  };

  const navigate = useNavigate();

  const handleOTPSubmit = async (
    values: ILoginVerify,
    { setSubmitting }: FormikHelpers<any>
  ) => {
    try {
      const response = await api.post(`${environment.baseUrl}/auth/verify`, {
        ...values,
        code: Number(values.code),
      });

      // Check if the login was successful
      if (response.data.success) {
        /**
         * notify success and navigate to admin dashboard
         */
        toast.success(response.data.message);
        localStorage.setItem("token", response.data.data.token);
        localStorage.removeItem("uuid_to_verify");

        navigate("/account/profile");
      } else {
        // Show an error message if verification was not successful
        console.log(response.data.message);
      }
    } catch (error) {
      // Handle any errors here
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <nav className="web_padding_x Navbar pt-8 pb-6 flex gap-8 2xl:gap-10 items-center whitespace-nowrap overflow-x-hidden">
        <NavLink to="/">
          <img src={logo} className="logo w-[100px] md:w-[180px]" alt="LOGO" />
        </NavLink>
      </nav>

      <section className="web_padding_y web_padding_x">
        <Formik
          initialValues={otpFormValues}
          validationSchema={otpValidationSchema}
          onSubmit={handleOTPSubmit}
        >
          {({ isValid, isSubmitting, setFieldValue, values }) => (
            <Form className="w-full max-w-[550px]">
              <h2 className="bar_above web_heading">Enter OTP</h2>

              <div className="flex flex-col w-fit items-start gap-8 mt-6 tall:mt-10 2xl:mt-10">
                <OTPInput
                  value={values.code}
                  onChange={(code) => {
                    setFieldValue("code", code);
                  }}
                  numInputs={5} // Specify the number of OTP digits
                  shouldAutoFocus={true}
                  renderInput={(inputProps, index) => (
                    <input
                      {...inputProps}
                      key={index}
                      className="border-black border border-solid block mr-4 rounded-lg !w-10 h-10 sm:!w-12 sm:h-12"
                    />
                  )}
                />
                <BlackButton
                  className="w-full"
                  type="submit"
                  text="Verify OTP"
                  isDisabled={!isValid || !values.code}
                  isLoading={isSubmitting}
                />
              </div>
            </Form>
          )}
        </Formik>
      </section>

      <WebsiteFooter />
    </>
  );
};
