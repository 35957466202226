import React, { useState } from "react";
import { environment } from "../../environments/environment";
import { CiMail } from "react-icons/ci";
import { RiPhoneFill } from "react-icons/ri";
import { NavLink, useNavigate } from "react-router-dom";
import { Formik, Form, FormikHelpers, Field } from "formik";
import * as Yup from "yup";
import InputField from "../../components/InputField";
import { WebsiteFooter } from "../../components/website-footer";
import logo from "../../assets/logo.png";
import { BlackButton } from "../../components/black-button";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import api from "../../api/api";

export const AdminLogin = () => {
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email").required("Email is required"),
    // phone: Yup.string().required("Phone number is required"),
  });

  const navigate = useNavigate();

  const handleSubmit = async (
    values: any,
    { setSubmitting }: FormikHelpers<any>
  ) => {
    try {
      const response = await api.post(
        `${environment.baseUrl}/admin/auth/signin`,
        {
          email: values.email,
          // phone: `+${values.phone}`,
        }
      );

      // Check if the login was successful
      if (response.data.success) {
        // notify success

        localStorage.setItem("uuid_to_verify", response.data.data.uuid);
        navigate("/admin/verify");
      } else {
        //
      }
    } catch (error) {
      //
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <nav className="web_padding_x Navbar pt-8 pb-6 flex gap-8 2xl:gap-10 items-center whitespace-nowrap overflow-x-hidden">
        <NavLink to="/">
          <img src={logo} className="logo w-[100px] md:w-[180px]" alt="LOGO" />
        </NavLink>
      </nav>

      <section className="web_padding_y web_padding_x">
        <Formik
          initialValues={{
            email: "",
            // phone: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isValid, isSubmitting }) => (
            <Form className="w-full max-w-[550px]">
              <h2 className="bar_above web_heading">Admin Login</h2>
              <div className="flex flex-col items-start gap-4 mt-6 tall:mt-10 2xl:mt-10">
                <InputField
                  name="email"
                  label="Email"
                  placeholder="Faithgrey@gmail.com"
                  type="email"
                  icon={<CiMail />}
                  required={true}
                />

                {/* <Field name="phone">
                  {({ field, form }: any) => (
                    <div className="grid grid-cols-[85px_1fr] w-full">
                      <label htmlFor="phone">Phone</label>
                      <PhoneInput
                        {...field}
                        onChange={(value) => form.setFieldValue("phone", value)}
                        inputClass="!w-full p !border-black !rounded-[20px] md:!rounded-[27px]"
                      />
                    </div>
                  )}
                </Field> */}

                <div className="max-w-[350px] w-full">
                  <BlackButton
                    className="w-full"
                    type="submit"
                    text="Sign in"
                    isDisabled={!isValid}
                    isLoading={isSubmitting}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </section>

      <WebsiteFooter />
    </>
  );
};
