import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import poweredBySttrand from '../../assets/dummy/powered-by-sttrand.svg';
import { NavLink, Outlet } from 'react-router-dom';
import bottle from '../../assets/images/about.png';
import blackAngleRight from '../../assets/icons/angle-right.png';
import blackAngleLeft from '../../assets/icons/angle-left.png';
import whiteAngleRight from '../../assets/icons/white-angle-right.png';
import whiteAngleLeft from '../../assets/icons/white-angle-left.png';
import {
  FaFacebookF,
  FaInstagram,
  FaSnapchatGhost,
  FaTwitter,
  FaTiktok,
  FaWhatsapp,
} from 'react-icons/fa';
import { Carousel } from 'react-responsive-carousel';
import { ItemCountInput } from '../../components/item-count-input';
import { environment } from '../../environments/environment';
import api from '../../api/api';
import { ICampaignAdmin } from '../../models/admin/campaigns';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { setSingleCampaign } from '../../store/authentication/actions/actions';
import { IAppState } from '../../store/store';
import { Box, Skeleton, Stack, Tab, Tabs } from '@mui/material';
import { CampaignSingle_Comments } from './campaign-single/comments';
import { CampaignSingle_1 } from './campaign-single/campaign';
import { CampaignSingle_Updates } from './campaign-single/updates';
import { currencyFormat, showCurrency } from '../../utility/currencyView';
import moment from 'moment';

export const CampaignSingle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Read currency from Redux state
  const currency = useSelector((state: IAppState) => state.shared.currency);

  const fetchCampaignDetails = async () => {
    try {
      const response = await api.get(
        `${environment.baseUrl}/campaign/fetch/single/${id}`,
        { params: { currency } }
      );

      const today = moment();
      let endsIn = 0;
      // Check if the deadline has passed
      const campaign = response.data.data?.campaign;

      if (today.isAfter(campaign.campaign_deadline)) {
        endsIn = 0;
      } else {
        // Calculate the number of days ahead
        endsIn = Math.abs(today.diff(campaign.campaign_deadline, 'days'));
      }

      setCampaignDetails({ ...response.data.data?.campaign, endsIn } ?? null);
      formatSizeOptions({ ...response.data.data?.campaign, endsIn });
      setCampaignBacked(response.data.data?.campaign_backed ?? false);
      setCurrent_orders(response.data.data?.current_orders ?? 0);
      setItemCount(response.data.data?.campaign?.minimum_quantity ?? 1);
      setMinQuantity(response.data.data?.campaign?.minimum_quantity ?? 1);
      dispatch(setSingleCampaign(response.data.data?.campaign));
    } catch (error) {
      navigate('/campaigns');
    }
  };

  const [activeTab, setActiveTab] = useState(0);

  const initialized = useRef(false);
  useEffect(() => {
    fetchCampaignDetails();
  }, []);

  // State to manage the value
  const [itemCount, setItemCount] = useState(1);
  const [minQuantity, setMinQuantity] = useState(1);

  // Function to handle value change in the parent component
  const handleItemCountChange = (newValue: number) => {
    setItemCount(newValue);
  };

  const [campaignDetails, setCampaignDetails] = useState<ICampaignAdmin | null>(
    null
  );

  const [campaignBacked, setCampaignBacked] = useState<boolean>(false);

  const [current_orders, setCurrent_orders] = useState<number>(0);

  const [currentCarouselSlide, setCarouselSlide] = useState(0);

  const [sizes, setSizes] = useState<any[]>([]);
  const [selectedSize, setSelectedSize] = useState<any>(null);

  const currencyView = useSelector((state: IAppState) =>
    showCurrency(state.shared.currency)
  );

  const formatSizeOptions = (campaign: ICampaignAdmin) => {
    const options: any[] = [];

    if (campaign.size_men_shoe) {
      options.push({
        label: 'Men Shoe',
        options: campaign.size_men_shoe
          .split(',')
          .map((x: any) => ({ value: x, label: x })),
      });
    }

    if (campaign.size_women_shoe) {
      options.push({
        label: 'Women Shoe',
        options: campaign.size_women_shoe
          .split(',')
          .map((x: any) => ({ value: x, label: x })),
      });
    }

    if (campaign.size_kg) {
      options.length = 0;
      options.push({
        label: 'Kg',
        options: campaign.size_kg
          .split(',')
          .map((x) => ({ value: x, label: x })),
      });
    }

    setSizes(options);
  };

  const formatBarWidth = () => {
    const valueCalculated =
      (current_orders * 100) / (campaignDetails?.end_goal_order_number ?? 0);
    if (valueCalculated < 50) {
      return valueCalculated + '%';
    } else if (valueCalculated <= 100) {
      return `calc(${valueCalculated}%)`;
    } else {
      return `100%`;
    }
  };

  // the carousel for campaign images
  const repeatedRef = campaignDetails && (
    <div className="relative">
      <Carousel
        autoPlay={true}
        infiniteLoop={false}
        interval={6000}
        showIndicators={false}
        showStatus={false}
        showThumbs={false}
        showArrows={false}
        selectedItem={currentCarouselSlide}
        onChange={(index) => setCarouselSlide(index)}
        className="campaign_single_carousel"
      >
        {campaignDetails &&
          campaignDetails.image_url
            ?.split(',')
            .map((img) => (
              <img
                key={img}
                src={img}
                className="object-cover [aspect-ratio:484/450]"
              />
            ))}
      </Carousel>

      {/* {!campaignDetails && (
        <Skeleton variant="rounded" width={4000} height={200} />
      )} */}

      <div className="flex justify-between items-center mt-4 tall:mt-8 2xl:mt-8">
        <div className="flex gap-2 items-center">
          <div className="w-[32px] h-[32px] bg-black rounded-full grid place-content-center">
            <FaFacebookF className="text-white text-base" />
          </div>
          <div className="w-[32px] h-[32px] bg-black rounded-full grid place-content-center">
            <FaInstagram className="text-white text-lg" />
          </div>
          <div className="w-[32px] h-[32px] bg-black rounded-full grid place-content-center">
            <FaSnapchatGhost className="text-white text-base" />
          </div>
          <div className="w-[32px] h-[32px] bg-black rounded-full grid place-content-center">
            <FaTwitter className="text-white text-base" />
          </div>
          <div className="w-[32px] h-[32px] bg-black rounded-full grid place-content-center">
            <FaTiktok className="text-white text-base" />
          </div>
          <div className="w-[32px] h-[32px] bg-black rounded-full grid place-content-center">
            <FaWhatsapp className="text-white text-lg" />
          </div>
        </div>

        <div className="flex gap-2 itemc-center">
          <button
            onClick={() => {
              setCarouselSlide(currentCarouselSlide - 1);
            }}
            className={
              `bg-[#000] w-[28px] h-[28px] rounded-full flex justify-center items-center ` +
              ' ' +
              `${currentCarouselSlide == 0 ? '!bg-[#ECECEC]' : ''}`
            }
            disabled={currentCarouselSlide == 0 ? true : false}
          >
            {currentCarouselSlide == 0 ? (
              <img className="w-5 h-5" src={blackAngleLeft} />
            ) : (
              <img className="w-5 h-5" src={whiteAngleLeft} />
            )}
          </button>
          <button
            onClick={() => {
              setCarouselSlide(currentCarouselSlide + 1);
            }}
            className={
              `bg-[#000] w-[28px] h-[28px] rounded-full flex justify-center items-center ` +
              ' ' +
              `${
                currentCarouselSlide ==
                campaignDetails.image_url?.split(',').length - 1
                  ? '!bg-[#ECECEC]'
                  : ''
              }`
            }
            disabled={
              currentCarouselSlide ==
              campaignDetails.image_url?.split(',').length - 1
                ? true
                : false
            }
          >
            {currentCarouselSlide ==
            campaignDetails.image_url?.split(',').length - 1 ? (
              <img className="w-5 h-5" src={blackAngleRight} />
            ) : (
              <img className="w-5 h-5" src={whiteAngleRight} />
            )}
          </button>
        </div>
      </div>
    </div>
  );

  return campaignDetails ? (
    <div className="lg:py-8 py-4 web_padding_x grid grid-cols-1 lg:grid-cols-2 gap-10 xl:gap-20">
      <div className="lg:order-1">
        <h2 className="web_heading_2 bar_above">
          {campaignDetails?.campaign_title}
        </h2>
        <h2 className="web_heading_2 mt-2">{campaignDetails?.product_title}</h2>

        <div className="mt-5 lg:hidden">{repeatedRef}</div>

        <div className="h-[250px] flex items-center justify-center px-5">
          <div className="relative p-[4px] flex-1 border-2 border-solid border-black h-10 rounded-[21px]">
            <div
              className="bg-black h-full rounded-[21px] slanted_bars !max-w-full"
              style={{
                width: formatBarWidth(),
              }}
            ></div>
            <div className="absolute w-full left-0 h-full top-0">
              <div
                className="campaign_dotted_line_with_circles"
                style={{
                  left: `${
                    ((campaignDetails?.production_goal_order_number ?? 0) *
                      100) /
                    (campaignDetails?.end_goal_order_number ?? 0)
                  }%`,
                }}
              >
                <span className="dot_above">
                  Production <br /> Goal <br />{' '}
                  {campaignDetails?.production_goal_order_number ?? 0} orders
                </span>
                <span className="dot_below">
                  {currencyView}{' '}
                  {currency === 'GHS'
                    ? currencyFormat(campaignDetails?.production_goal_price) ??
                      0
                    : currency === 'NGN'
                    ? currencyFormat(
                        campaignDetails?.production_goal_price_ngn
                      ) ?? 0
                    : currencyFormat(
                        campaignDetails?.production_goal_price_zar
                      ) ?? 0}{' '}
                  <br /> P.G Price
                </span>
              </div>

              <div className="campaign_dotted_line_with_circles right-0">
                <span className="dot_above">
                  End <br /> Goal <br />{' '}
                  {campaignDetails?.end_goal_order_number ?? 0} orders
                </span>
                <span className="dot_below">
                  {currencyView}{' '}
                  {currency === 'GHS'
                    ? currencyFormat(campaignDetails?.end_goal_price) ?? 0
                    : currency === 'NGN'
                    ? currencyFormat(campaignDetails?.end_goal_price_ngn) ?? 0
                    : currencyFormat(campaignDetails?.end_goal_price_zar) ?? 0}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-between font-bold gap-2">
          <div>
            <div className="text-base md:text-[24px]">
              {currencyView}{' '}
              {currency === 'GHS'
                ? currencyFormat(campaignDetails?.current_price) ?? 0
                : currency === 'NGN'
                ? currencyFormat(campaignDetails?.current_price_ngn) ?? 0
                : currencyFormat(campaignDetails?.current_price_zar) ?? 0}
            </div>
            <div className="text-xs md:text-sm">Current Price</div>
          </div>

          <div className="bg-black w-[2px] h-[50px]"></div>

          <div>
            <div className="text-base md:text-[24px]">{current_orders}</div>
            <div className="text-xs md:text-sm">Current Orders</div>
          </div>

          <div className="bg-black w-[2px] h-[50px]"></div>

          <div>
            <div className="text-base md:text-[24px]">
              {campaignDetails?.endsIn}
            </div>
            <div className="text-xs md:text-sm">Days To Go</div>
          </div>
        </div>
        {/* grid-cols-[auto_1fr] */}
        <form className="mt-10 flex flex-col tall:mt-14 2xl:mt-14   gap-4 sm:gap-6 lg:gap-7 ">
          <div className="flex items-center gap-9">
            <div>
              <div className="sm:text-lg font-bold">Size</div>
              <div className="text-xs sm:text-sm">(Size Chart)</div>
            </div>
            <Select
              className="flex-1"
              classNamePrefix="custom_select"
              options={sizes}
              onChange={(e) => {
                setSelectedSize(e.value);
              }}
              placeholder="Select size"
            />
          </div>

          <div className="sm:text-lg font-bold flex items-center gap-5">
            <p>Quantity</p>
            <div className="w-[120px]">
              <ItemCountInput
                value={itemCount}
                onValueChange={handleItemCountChange}
                min={minQuantity}
              />
            </div>
            <span className="font-normal text-sm">
              Minimum Quantity: {minQuantity}
            </span>
          </div>

          <div className="col-span-2">
            <button
              type="button"
              onClick={() => {
                sessionStorage.setItem('campaignToBack', id!);
                sessionStorage.setItem('quantity', itemCount.toString());
                if (selectedSize) {
                  sessionStorage.setItem('size', selectedSize.toString());
                }
                sessionStorage.setItem(
                  'amount',
                  currency === 'GHS'
                    ? campaignDetails!.current_price.toString()
                    : currency === 'NGN'
                    ? campaignDetails!.current_price_ngn.toString()
                    : campaignDetails!.current_price_zar.toString()
                );
                sessionStorage.setItem('currency', currency);
                navigate(
                  `/back-campaign/${id}?quantity=${itemCount}&amount=${
                    currency === 'GHS'
                      ? campaignDetails!.current_price.toString()
                      : currency === 'NGN'
                      ? campaignDetails!.current_price_ngn.toString()
                      : campaignDetails!.current_price_zar.toString()
                  }&currency=${currency}&size=${selectedSize}`
                );
              }}
              className="px-6 py-2 rounded-[24px] block w-[80%] max-w-[450px] mx-auto text-white bg-[#028858] hover:bg-[#015738]"
            >
              Back this Campaign
            </button>
            {/* <img src={poweredBySttrand} className="block mx-auto mt-3" alt="" /> */}

            <div className="mt-4 text-center">
              This product will go into production at the end of the campaign at
              least if the Production Goal is met by "Day", "Month", "Year" at
              11:59pm PST.
            </div>
          </div>
        </form>
      </div>

      <div>
        <div className="hidden lg:block">{repeatedRef}</div>

        <Box
          sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '20px' }}
        >
          <Tabs
            value={activeTab}
            onChange={(event, newValue) => {
              setActiveTab(newValue);
            }}
            aria-label="basic tabs example"
          >
            <Tab label="Comments" />
            <Tab label="Campaign" />
            <Tab label="Updates" />
          </Tabs>
        </Box>
        <div className="mt-5">
          {activeTab === 0 && (
            <CampaignSingle_Comments campaignBacked={campaignBacked} />
          )}
          {activeTab === 1 && <CampaignSingle_1 />}
          {activeTab === 2 && <CampaignSingle_Updates />}
        </div>
      </div>
    </div>
  ) : (
    <div className="web_padding_y web_padding_x">
      <Stack spacing={1}>
        <Stack spacing={1} direction={'row'}>
          <Skeleton variant="rounded" width={400} height={200} />
          <Skeleton variant="rounded" width={400} height={200} />
          <Skeleton variant="rounded" width={400} height={200} />
        </Stack>
        <Stack spacing={1} direction={'row'}>
          <Skeleton variant="rounded" width={400} height={200} />
          <Skeleton variant="rounded" width={400} height={200} />
          <Skeleton variant="rounded" width={400} height={200} />
        </Stack>
      </Stack>
    </div>
  );
};
