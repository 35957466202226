import React from "react";
import { FaFacebookF, FaTiktok, FaSnapchatGhost } from "react-icons/fa";
import { GrTwitter } from "react-icons/gr";
import { BsWhatsapp, BsInstagram } from "react-icons/bs";

export const ThankYou = () => {
  return (
    <section className="about_layout_privacy lg:mt-[100px]">
      <div className="flex flex-col items-center justify-center text-center gap-5 m-[100px]">
        <p className="text-2xl text-center font-bold">Thank you!</p>
        <div className="flex flex-wrap justify-center items-center gap-5 text-white text-2xl">
          <div className="bg-black p-3 rounded-full">
            <BsWhatsapp />
          </div>
          <div className="bg-black p-3 rounded-full">
            <FaFacebookF />
          </div>
          <div className="bg-black p-3 rounded-full">
            <BsInstagram />
          </div>
          <div className="bg-black p-3 rounded-full">
            <FaSnapchatGhost />
          </div>
          <div className="bg-black p-3 rounded-full">
            <FaTiktok />
          </div>
          <div className="bg-black p-3 rounded-full">
            <GrTwitter className="text-2xl" />
          </div>
        </div>
        <p className="text-center">
          Thank you very much for your order. We are glad you decided to <br />{" "}
          use our platform
        </p>
      </div>
    </section>
  );
};
