import { CurrencyEnum } from "../models/currency";

export const showCurrency = (currency: CurrencyEnum) => {
  if (currency === CurrencyEnum.NGN) {
    return "NGN";
  } else if (currency === CurrencyEnum.ZAR) {
    return "ZAR";
  } else {
    return "GH₵";
  }
};

export function currencyFormat(num: number) {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}
