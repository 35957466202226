import { useSelector } from "react-redux";
import howItWorksImg from "../../assets/maskedb.jpg";
import { IAppState } from "../../store/store";
import { HowItWorks2 } from "./HowItWorks2";
import { useState } from "react";

export const HowItWorks = () => {
  const isGhanaSelected = useSelector((state: IAppState) =>
    state.shared.currency === "NGN" ? false : true
  );

  const [isEnglish, setIsEnglish] = useState(true);

  return (
    <>
      <div className="flex items-center justify-center gap-4 mb-5 mt-10">
        <p
          onClick={() => setIsEnglish(true)}
          className={`text-black bg-white border border-black   rounded-2xl w-[150px] flex items-center justify-center py-3 hover:bg-black hover:text-white cursor-pointer ${
            isEnglish ? " !bg-black  !text-white" : ""
          }`}
        >
          ENGLISH
        </p>
        {isGhanaSelected && (
          <p
            onClick={() => setIsEnglish(false)}
            className={`text-black bg-white border border-black  rounded-2xl  w-[150px] flex items-center justify-center py-3 hover:bg-black hover:text-white cursor-pointer" ${
              !isEnglish ? " !bg-black  !text-white" : ""
            }`}
          >
            TWI
          </p>
        )}
      </div>
      <HowItWorks2 isEnglish={isEnglish} />
    </>
  );
};
