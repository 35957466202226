import howItWorksImg from "../../assets/maskedb.jpg";

export const HowItWorks2 = ({ isEnglish = true }: { isEnglish: boolean }) => {
  return (
    <>
      <main className="web_padding_x web_padding_y ">
        <div className="web_custom_layout_1">
          {/* <img className="custom_layout_img_1" src={howItWorksImg} alt="" /> */}
          {isEnglish && (
            <div className="custom_layout_img_1">
              <iframe
                style={{ width: "100%", height: "100%" }}
                src="https://www.youtube.com/embed/JMMG8Delt64"
                title="How it Works [Faith Grey] - ENGLISH"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
            </div>
          )}
          {!isEnglish && (
            <div className="custom_layout_img_1">
              <iframe
                style={{ width: "100%", height: "100%" }}
                src="https://www.youtube.com/embed/qb2v52w8dsY"
                title="How it Works [Faith Grey] - ENGLISH"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
            </div>
          )}
          <div className="">
            <h2 className="web_heading bar_above">How it works</h2>

            <p className="web_paragraph_1 mt-4">
              Faith Grey is a platform powered by Sttrand. Our platform utilizes
              social applications to allow consumers to participate in
              campaigns. Our campaigns are designed to incentivize Backers to
              join while decreasing the price of the items listed in the
              campaign.
              <br />
              <br />
              Campaign backers get charged the “End Goal Price” once they back a
              campaign. If a campaign was to conclude with the final price
              landing between the “Production Goal Price” and “End Goal Price”,
              Backers will be charged a final amount by subtracting the “End
              Goal Price” from the final price. Backers are not charged again if
              the “End Goal Price” is achieved. Backers will be issued a refund
              if the campaign’s “Production Goal” is not met at the conclusion
              of a campaign.
              <br />
              <br />
              Users can still back a campaign if there are days or time left
              even after an “End Goal Price” is met.
            </p>
          </div>
        </div>
      </main>
    </>
  );
};
