import homeImg from "../../assets/images/homeImg.png";
import homeImg2 from "../../assets/images/homeImg2.png";
import homeImg3 from "../../assets/images/homeImg3.png";
// import howItWorksImg from "../../assets/images/how-it-works.svg";
import styled from "styled-components";
import { HowItWorks2 } from "./HowItWorks2";
import { SingleBottle } from "../../components/SingleBottle";
import { Button } from "../../components/Button";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useEffect, useRef, useState } from "react";
import { BlackButton } from "../../components/black-button";
import axios from "axios";
import { environment } from "../../environments/environment";
import { ICampaignAdmin } from "../../models/admin/campaigns";
import { Stack, Skeleton } from "@mui/material";
import { useSelector } from "react-redux";
import { IAppState } from "../../store/store";
import api from "../../api/api";
import { useNavigate } from "react-router-dom";

export const Homepage = () => {
  const initialized = useRef(false);
  const [activeHeadingText, setActiveHeadingText] = useState(0);
  const navigate = useNavigate();
  const headingTexts: any[] = [
    {
      h2: "As more people back a campaign, prices decrease",
      p: "With increased support, campaigns benefit from economies of scale leading to lower production costs and prices.",
      img: homeImg2,
    },
    {
      h2: "Increase Your Purchasing Power by Joining Our Community",
      img: homeImg3,
    },
  ];

  const [campaigns, setCampaigns] = useState<ICampaignAdmin[] | null>(null);
  const [ordersData, setOrdersData] = useState<{ [key: string]: number }>({});
  const [currentPage, setCurrentPage] = useState(1);
  // Read currency from Redux state
  const currency = useSelector((state: IAppState) => state.shared.currency);
  const hasSwitchedCurrency = useSelector(
    (state: IAppState) => state.shared.hasSwitchedCurrency
  );

  useEffect(() => {
    fetchCampaigns(currentPage);
  }, [currentPage, currency]);

  const fetchCampaigns = async (page: number) => {
    try {
      const response = await api.get(
        `${environment.baseUrl}/campaign/fetch?page=${page}`,
        { params: { currency } }
      );

      setCampaigns(response.data.data?.campaigns?.rows ?? null);
      setOrdersData(response.data.data?.current_orders ?? {});
    } catch (error) {}
  };

  return (
    <>
      <main className="about_layout">
        <div className="about_layout_content">
          <h2 className="web_heading bar_above">
            {headingTexts[activeHeadingText]?.h2}
          </h2>
          <p className="web_paragraph_1 mt-2">
            {headingTexts[activeHeadingText]?.p}
          </p>
          <br />

          <BlackButton
            onClick={() => navigate("/campaigns")}
            text="View Campaigns"
          ></BlackButton>
        </div>

        <Carousel
          autoPlay={true}
          infiniteLoop={true}
          interval={6000}
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          className="home_carousel"
          onChange={(index) => setActiveHeadingText(index)}
        >
          <div>
            <img src={homeImg2} alt="Man holding Water Bottle" />
          </div>
          <div>
            <img src={homeImg3} alt="Man holding Water Bottle" />
          </div>
        </Carousel>
      </main>
      <Wrapper className="web_padding_y web_padding_x">
        <h2 className="web_heading">Join a Campaign</h2>
        {campaigns && (
          <>
            <div className="grid gap-6 lg:grid-cols-3">
              {campaigns?.map((campaign: ICampaignAdmin, index) => (
                <SingleBottle
                  key={campaign.uuid}
                  campaign={campaign}
                  currentOrders={ordersData[campaign.uuid]}
                />
              ))}
            </div>
          </>
        )}
        {campaigns?.length === 0 && (
          <>
            <div className="border border-dashed text-center border-gray-400 mt-4 mx-auto max-w-[450px] rounded-lg px-10 py-20">
              No campaigns found
            </div>
          </>
        )}
        {!campaigns && (
          <>
            <Stack spacing={1}>
              <Stack spacing={1} direction={"row"}>
                <Skeleton variant="rounded" width={400} height={200} />
                <Skeleton variant="rounded" width={400} height={200} />
                <Skeleton variant="rounded" width={400} height={200} />
              </Stack>
            </Stack>
          </>
        )}
        <Button
          className="btn"
          name="view more"
          onClick={() => navigate("/campaigns")}
          icon={<HiOutlineArrowNarrowRight />}
        />
      </Wrapper>
      <HowItWorks2 isEnglish={true} />
    </>
  );
};

const Wrapper = styled.section`
  h2 {
    margin-bottom: 3.375rem;
  }
  /* .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2em;
  } */
  .btn {
    margin: 0 auto;
    display: block;
    text-align: center;
    margin-top: 4.7rem;
    /*  */
  }
`;
