import React, { useEffect, useState } from "react";
import { DashboardTitle } from "../../components/DashboardTitle";
import api from "../../api/api";
import { environment } from "../../environments/environment";
import moment from "moment";
import { useSelector } from "react-redux";
import { IAppState } from "../../store/store";

export const PaymentHistory = () => {
  const [paymentHistory, setPaymentHistory] = useState<any[] | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchPaymentHistory(currentPage);
  }, [currentPage]);

  const fetchPaymentHistory = async (page: number) => {
    try {
      const response = await api.get(
        `${environment.baseUrl}/payment/history?page=${page}`,
        { params: { currency } }
      );

      setPaymentHistory(response.data.data?.history?.rows ?? null);
      setTotalPages(response.data.totalPages);
    } catch (error) {}
  };

  const currency = useSelector((state: IAppState) => state.shared.currency);

  return (
    <section>
      <DashboardTitle text="Payment History"></DashboardTitle>

      <div className="mt-5 overflow-x-auto bg-white p-8 border border-[#DEDEDE] border-solid rounded-[18px] text-black">
        <table className="w-full whitespace-nowrap">
          <thead>
            <tr className="border-b border-solid border-b-black">
              <th className="text-left font-normal pb-7">ORDER TIME</th>
              <th className="text-left pl-5 font-normal pb-7">
                DELIVERY ADDRESS
              </th>
              <th className="text-left pl-5 font-normal pb-7">PHONE</th>
              <th className="text-left pl-5 font-normal pb-7">
                PAYMENT METHOD
              </th>
              <th className="text-left pl-5 font-normal pb-7">ORDER AMOUNT</th>
              <th className="text-left pl-5 font-normal pb-7">STATUS</th>
            </tr>
          </thead>
          <tbody>
            {paymentHistory &&
              paymentHistory.map((item, index) => (
                <tr className="">
                  <td className="pt-7">
                    {moment(item.CreatedAt).format("MMM DD, YYYY")}
                  </td>
                  <td className="pt-7 pl-5">
                    {item.house_number} {item.street_name} {item.city}
                  </td>
                  <td className="pt-7 pl-5">{item.phone}</td>
                  <td className="pt-7 pl-5">CASH ON DELIVERY</td>
                  <td className="pt-7 pl-5">₵ {item.amount}</td>
                  <td className="pt-7 pl-5">
                    <div className="py-1 px-5 text-center w-fit mr-auto text-sm leading-[14px] rounded-[13px] bg-black text-white">
                      PENDING
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};
