import styled from 'styled-components';
import bottle from '../assets/black-bottle-back.png';
import { GrLocation } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import { ICampaignAdmin } from '../models/admin/campaigns';
import { useSelector } from 'react-redux';
import { IAppState } from '../store/store';
import { currencyFormat, showCurrency } from '../utility/currencyView';
import moment from 'moment';

export const SingleBottle = ({
  campaign,
  currentOrders = 0,
}: {
  campaign: ICampaignAdmin;
  currentOrders: number;
}) => {
  const currencyView = useSelector((state: IAppState) =>
    showCurrency(state.shared.currency)
  );
  const currency = useSelector((state: IAppState) => state.shared.currency);

  const formatBarWidth = () => {
    const valueCalculated =
      (currentOrders * 100) / (campaign?.end_goal_order_number ?? 0);
    if (valueCalculated < 50) {
      return valueCalculated + '%';
    } else if (valueCalculated <= 100) {
      return `calc(${valueCalculated}%`;
    } else {
      return `100%`;
    }
  };

  const getEndsIn = () => {
    const today = moment();
    let endsIn = 0;
    // Check if the deadline has passed
    if (today.isAfter(campaign.campaign_deadline)) {
      endsIn = 0;
    } else {
      // Calculate the number of days ahead
      endsIn = Math.abs(today.diff(campaign.campaign_deadline, 'days'));
    }
    return endsIn;
  };

  return (
    <Wrapper>
      <Link to={`/campaigns/${campaign.uuid}`}>
        <div className="group overflow-hidden">
          <img
            src={campaign.image_url?.split(',')[0] || bottle}
            className=" duration-500 w-full h-full object-cover"
            alt="water bottle"
          />
        </div>
      </Link>
      <div className="bg-[#F3F3F3] p-[24px]">
        <h3 className="text-black font-bold text-lg 2xl:text-xl ">
          {' '}
          {campaign.campaign_title}{' '}
        </h3>
        <h4 className="text-black font-bold text-lg 2xl:text-xl 2xl:mt-[8px]">
          {' '}
          {campaign.product_title}{' '}
        </h4>

        <div className="h-[200px] flex items-center justify-center px-2">
          <div className="relative p-[4px] flex-1 border-2 border-solid border-black h-10 rounded-[21px]">
            <div
              className="bg-black h-full rounded-[21px] slanted_bars"
              style={{
                width: formatBarWidth(),
              }}
            ></div>
            <div className="absolute w-full left-0 h-full top-0">
              <div
                className="campaign_dotted_line_with_circles"
                style={{
                  left: `${
                    ((campaign?.production_goal_order_number ?? 0) * 100) /
                    (campaign?.end_goal_order_number ?? 0)
                  }%`,
                }}
              >
                <span className="dot_above">
                  P. G. <br /> {campaign?.production_goal_order_number ?? 0}
                </span>
                <span className="dot_below">
                  {currencyView}{' '}
                  {currency === 'GHS'
                    ? currencyFormat(campaign?.production_goal_price) ?? 0
                    : currency === 'ZAR'
                    ? currencyFormat(campaign?.production_goal_price_zar) ?? 0
                    : currencyFormat(campaign?.production_goal_price_ngn) ??
                      0}{' '}
                  <br /> P.G Price
                </span>
              </div>

              <div className="campaign_dotted_line_with_circles right-0">
                <span className="dot_above">
                  E. G. <br /> {campaign?.end_goal_order_number ?? 0}
                </span>
                <span className="dot_below">
                  {currencyView}{' '}
                  {currency === 'GHS'
                    ? currencyFormat(campaign?.end_goal_price) ?? 0
                    : currency === 'ZAR'
                    ? currencyFormat(campaign?.end_goal_price_zar) ?? 0
                    : currencyFormat(campaign?.end_goal_price_ngn) ?? 0}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap items-end gap-5 pb-5">
          <div>
            <h5 className="text-[16px] xl:text-[24px] leading-[24px] font-bold">
              {currencyView}{' '}
              <span className="text-[14px] xl:text-[18px]">
                {currency === 'GHS'
                  ? currencyFormat(campaign.current_price)
                  : currency === 'ZAR'
                  ? currencyFormat(campaign.current_price_zar)
                  : currencyFormat(campaign.current_price_ngn)}
              </span>
            </h5>
            <p className="whitespace-nowrap text-[14px] xl:mt-2 leading-[19px]">
              Current Price
            </p>
          </div>

          <div>
            <h5 className="font-bold leading-[24px] text-[16px] xl:text-[24px]">
              {currentOrders}
            </h5>
            <p className="whitespace-nowrap text-[14px] xl:mt-2 leading-[19px]">
              Current Orders
            </p>
          </div>

          <div>
            <h5 className="font-bold leading-[24px] text-[16px] xl:text-[24px]">
              {getEndsIn()}
            </h5>
            <p className="whitespace-nowrap text-[14px] xl:mt-2 leading-[19px]">
              Days to go
            </p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.article`
  color: #000000;
  cursor: pointer;
  /* hover: translateY(-10px); */

  img {
    width: 100%;
    aspect-ratio: 453 / 299;
  }
  .stripe {
    height: 16px;
    background-color: black;
    width: 90%;
    max-width: 280px;
    border-radius: 21px;
  }

  svg {
    font-weight: bold;
    width: 14px;
    display: inline;
  }
`;
