import React from "react";
import { AiOutlineMail } from "react-icons/ai";
import padlock from "../../assets/padlock.png";

const ForgotPassword = () => {
  return (
    <main className="about_privacy flex items-center justify-center flex-col  md:flex-row">
      <div className="about_layout_privacy lg:mt-[100px] flex flex-col gap-5 ">
        <h2 className="web_heading bar_above">Forgot Your Password</h2>
        <p>Enter your email address associated with your account.</p>
        <div className="flex justify-center items-center gap-3">
          <p className="text-xl font-bold">Email</p>
          <div className="relative flex-1 xl:ml-10">
            <AiOutlineMail className="absolute center_y left-4 text-2xl" />
            <input
              type="search"
              placeholder="Faithgrey@gmail.com"
              className="outline-none border-2 border-black border-solid rounded-full pl-[3rem] pr-6 py-6 h-[41px] w-full bg-[#EEEEEE] text-black"
            />
          </div>
        </div>
        <button className="w-1/3 text-white px-4 py-3 bg-black hover:text-black hover:bg-white border rounded-full text-black">
          Confirm
        </button>
      </div>

      <div className="about_layout_privacy w-1/2 ">
        <img src={padlock} alt="padlock" />
      </div>
    </main>
  );
};

export default ForgotPassword;
