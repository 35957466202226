import React, { useEffect, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import hamburger from '../../assets/icons/black-hamburger.svg';
import axios from 'axios';
import { environment } from '../../environments/environment';
import Pagination from '@mui/material/Pagination';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { ICampaignAdmin } from '../../models/admin/campaigns';
import { FormattedDate, IntlProvider } from 'react-intl';
import api from '../../api/api';
import { toast } from 'react-toastify';

export const AdminCampaigns = () => {
  const [campaigns, setCampaigns] = useState<ICampaignAdmin[] | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchCampaigns(currentPage);
  }, [currentPage]);

  const fetchCampaigns = async (page: number) => {
    try {
      const response = await api.get(
        `${environment.baseUrl}/campaign/fetch?page=${page}`
      );

      setCampaigns(response.data.data?.campaigns?.rows ?? null);
      setTotalPages(response.data.data?.campaigns?.total_pages ?? 1);
    } catch (error) {}
  };

  const deleteCampaign = async (uuid: string) => {
    try {
      const response = await api.delete(
        `${environment.baseUrl}/admin/campaign/delete/${uuid}`
      );

      if (response.data.success) {
        toast.success(`${response.data.message}`);
      }

      fetchCampaigns(currentPage);
    } catch (error) {}
  };

  function handlePageChange(event: React.ChangeEvent<unknown>, page: number) {
    setCurrentPage(page);
  }

  return (
    <section className="font-montserrat">
      <IntlProvider locale="en">
        <div className="flex items-center flex-wrap gap-6 justify-between">
          <div className="flex items-center gap-3">
            <img src={hamburger} alt="campaigns-icon" className="w-[18px]" />
            <h5 className="text-[22px] font-bold">Campaigns</h5>
          </div>

          <Link to="/admin/campaigns/new">
            <button className="bg-black text-white flex items-center gap-2 p-2 rounded-md  focus:outline-none focus:shadow-outline transform transition duration-300 perspective-origin-center hover:-rotate-y-6 hover:scale-105 active:rotate-y-180 active:scale-100">
              <FiPlus />
              <span>ADD NEW CAMPAIGN</span>
            </button>
          </Link>
        </div>

        <div className="mt-7 bg-white text-black p-4 md:p-8 tall:py-[60px] xl:px-9 rounded-[12px] md:rounded-[18px]">
          {campaigns ? (
            <>
              {campaigns.map((campaign, index) => (
                <div
                  key={campaign.uuid}
                  className={`grid gap-6 lg:grid-cols-[250px_auto_1fr] xl:gap-10  ${
                    index === 0 ? '' : ' mt-5'
                  }`}
                >
                  <div className="">
                    <Link to={`/admin/campaigns/${campaign.uuid}`}>
                      <img
                        src={campaign.image_url?.split(',')[0]}
                        className="w-full h-full object-cover object-top"
                        alt=""
                      />
                    </Link>
                  </div>

                  <div className="">
                    <h4 className="font-bold text-[22px]">
                      {campaign.campaign_title}
                    </h4>
                    <p className="font-bold text-sm mt-2">250% funded</p>
                    <p className="text-sm mt-2">By Robyn</p>

                    <div className="mt-2 flex gap-4">
                      <Link to={`/admin/campaigns/${campaign.uuid}`}>
                        <span className="underline font-medium hover:font-bold text-sm">
                          View
                        </span>
                      </Link>
                      <button
                        onClick={() => {
                          deleteCampaign(campaign.uuid);
                        }}
                        className="text-red-600 underline font-medium hover:font-bold text-sm"
                      >
                        Delete
                      </button>
                    </div>
                  </div>

                  <div className="overflow-x-auto">
                    <table className="w-full whitespace-nowrap">
                      <thead>
                        <tr>
                          <th className="text-left font-normal pb-7">
                            <FormattedDate
                              value={campaign.CreatedAt}
                              year="numeric"
                              month="short"
                              day="2-digit"
                            />
                          </th>
                          <th className="text-left pl-5 font-normal pb-7">
                            LOCATION
                          </th>
                          <th className="text-left pl-5 font-normal pb-7">
                            STATUS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="border-t border-solid border-t-black">
                          <td className="pt-7">FEB 20, 2023</td>
                          <td className="pt-7 pl-5">{campaign.location}</td>
                          <td className="pt-7 pl-5">
                            <div className="py-1 px-5 text-center w-fit mr-auto text-sm leading-[14px] rounded-[13px] bg-black text-white">
                              ACTIVE
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}

              <div className="mt-4 flex justify-center">
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  variant="outlined"
                  shape="rounded"
                />
              </div>
            </>
          ) : (
            <>
              <Stack spacing={1}>
                <Stack spacing={1} direction={'row'}>
                  <Skeleton variant="rounded" width={210} height={60} />
                  <Skeleton variant="rounded" width={410} height={60} />
                  <Skeleton variant="rounded" width={1100} height={60} />
                </Stack>
                <Stack spacing={1} direction={'row'}>
                  <Skeleton variant="rounded" width={210} height={60} />
                  <Skeleton variant="rounded" width={410} height={60} />
                  <Skeleton variant="rounded" width={1100} height={60} />
                </Stack>
              </Stack>
            </>
          )}
        </div>
      </IntlProvider>
    </section>
  );
};
