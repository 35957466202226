import React, { useEffect, useState } from "react";
import { DashboardTitle } from "../../components/DashboardTitle";
import { AccountInput } from "../../components/account/AccountInput";
import { BlackButton } from "../../components/black-button";
import { useLocation, useParams } from "react-router-dom";
import { Skeleton, TextField } from "@mui/material";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { toast } from "react-toastify";
import api from "../../api/api";
import { environment } from "../../environments/environment";
import dummyUserImage from "../../assets/dummy/dummy-user.png";
import imageUploadIcon from "../../assets/icons/image-upload.png";
import axios from "axios";
import { useSelector } from "react-redux";
import { IAppState } from "../../store/store";

const initialValues = {
  first_name: "",
  last_name: "",
  middle_name: "",
  personal_id_number: "",
  phone: "",
  country: "",
  city: "",
  pickup_location: "",
  street_name: "",
  closest_landmark: "",
  gps_address: "",
  house_number: "",
  email: "",
};

export const EditProfile = () => {
  const { uuid } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Now you can access individual query parameters
  const quantity = Number(queryParams.get("quantity"));
  const amount = Number(queryParams.get("amount"));
  const currency = queryParams.get("currency");
  const size = queryParams.get("size");

  const countryCardView = useSelector((state: IAppState) =>
    state.shared.currency === "NGN" ? "Nigerian" : "Ghana"
  );

  const [profileImage, setProfileImage] = useState<any>(null);
  const [profileImageLoading, setProfileImageLoading] =
    useState<boolean>(false);

  // Function to set initial values based on user data
  const setInitialValues = () => {
    const user: any = JSON.parse(localStorage.getItem("user")!);

    // if it's only 'name' that's still stored for the user,
    // separate the first name and lastname from name
    if (!user.first_name && !user.last_name && user.name) {
      user.first_name = user.name.split(" ")[1];
      user.last_name = user.name.split(" ")[0];
    }

    return user;
  };

  // Read currency from Redux state
  // const currency = useSelector((state: IAppState) => state.shared.currency);

  const handleSubmit = async (
    values: any,
    { setSubmitting }: FormikHelpers<any>
  ) => {
    if (uuid) {
      // when the user is backing a campaign
      try {
        let payload = {
          ...values,
          currency,
          quantity: quantity ?? 1,
          campaign_uuid: uuid,
          amount,
          size,
        };

        const response = await api.post(
          `${environment.baseUrl}/campaign/order`,
          payload
        );

        if (response.data.success) {
          toast.success(response.data.message);
          window.location.replace(response.data.data.payment_link);
        } else {
        }
      } catch (error) {
      } finally {
        setSubmitting(false);
      }
    } else {
      // if user is updating profile
      try {
        let payload = {
          ...values,
          image_url: profileImage,
        };

        const response = await api.put(
          `${environment.baseUrl}/profile/edit`,
          payload
        );

        if (response.data.success) {
          localStorage.setItem("user", JSON.stringify(response.data.data));
          toast.success(response.data.message);
        } else {
        }
      } catch (error) {
      } finally {
        setSubmitting(false);
      }
    }
  };

  useEffect(() => {
    sessionStorage.removeItem("campaignToBack");

    const user: any = JSON.parse(localStorage.getItem("user")!);
    setProfileImage(user?.image_url || null);
  }, []);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      handleImage(file);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    handleImage(file);
  };

  const handleImage = (file: any) => {
    // Handle image upload here
    setProfileImageLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "cqom5ijc");

    axios
      .post(`https://api.cloudinary.com/v1_1/dlicyptzz/image/upload`, formData)
      .then((response) => {
        setProfileImage(response.data.secure_url);
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
      })
      .finally(() => {
        setProfileImageLoading(false);
      });
  };

  return (
    <section className={`${uuid ? "web_padding_x web_padding_y" : ""} !pt-5`}>
      <DashboardTitle
        text={uuid ? "Back Campaign" : "Edit Profile"}
      ></DashboardTitle>

      <Formik
        initialValues={setInitialValues()}
        // validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isValid, isSubmitting }) => (
          <Form>
            <div className="dashboard_subtitle mt-6 md:mt-4">
              Your Information
            </div>

            <div className="grid gap-5 mt-4 md:grid-cols-2 xl:grid-cols-3">
              <div className="">
                <h6 className="font-bold text-sm md:text-base">
                  First Name{" "}
                  <span className="font-normal text-[13px] md:text-sm">
                    (As displayed on {countryCardView} Card)
                  </span>
                </h6>
                <Field
                  name="first_name"
                  as={TextField}
                  className="admin_input_1 block_input"
                  placeholder="First Name...."
                />
              </div>
              <div className="">
                <h6 className="font-bold text-sm md:text-base">
                  Middle Name{" "}
                  <span className="font-normal text-[13px] md:text-sm">
                    (As displayed on {countryCardView} Card)
                  </span>
                </h6>
                <Field
                  name="middle_name"
                  as={TextField}
                  className="admin_input_1 block_input"
                  placeholder="Middle Name...."
                />
              </div>
              <div className="">
                <h6 className="font-bold text-sm md:text-base">
                  Last Name{" "}
                  <span className="font-normal text-[13px] md:text-sm">
                    (As displayed on {countryCardView} Card)
                  </span>
                </h6>
                <Field
                  name="last_name"
                  as={TextField}
                  className="admin_input_1 block_input"
                  placeholder="Last Name...."
                />
              </div>
              <div className="">
                <h6 className="font-bold text-sm md:text-base">
                  Personal ID Number{" "}
                  <span className="font-normal text-[13px] md:text-sm">
                    (As displayed on {countryCardView} Card)
                  </span>
                </h6>
                <Field
                  name="personal_id_number"
                  as={TextField}
                  className="admin_input_1 block_input"
                  placeholder="Personal ID Number"
                />
              </div>
              <div className="">
                <h6 className="font-bold text-sm md:text-base">Phone</h6>
                <Field
                  name="phone"
                  as={TextField}
                  className="admin_input_1 block_input"
                />
              </div>
              <div className="">
                <h6 className="font-bold text-sm md:text-base">Email</h6>
                <Field
                  name="email"
                  as={TextField}
                  className="admin_input_1 block_input"
                  placeholder="faith@grey.com"
                />
              </div>
            </div>

            <div className="dashboard_subtitle mt-6">Your Address</div>

            <div className="grid gap-5 mt-4 md:grid-cols-2 xl:grid-cols-3">
              <div className="">
                <h6 className="font-bold text-sm md:text-base">Country</h6>
                <Field
                  name="country"
                  as={TextField}
                  className="admin_input_1 block_input"
                  placeholder="Country"
                />
              </div>
              <div className="">
                <h6 className="font-bold text-sm md:text-base">City/Town</h6>
                <Field
                  name="city"
                  as={TextField}
                  className="admin_input_1 block_input"
                  placeholder="City"
                />
              </div>
              <div className="">
                <h6 className="font-bold text-sm md:text-base">GPS Address</h6>
                <Field
                  name="gps_address"
                  as={TextField}
                  className="admin_input_1 block_input"
                  placeholder="GPS Address"
                />
              </div>
              <div className="">
                <h6 className="font-bold text-sm md:text-base">House Number</h6>
                <Field
                  name="house_number"
                  as={TextField}
                  className="admin_input_1 block_input"
                  placeholder="House Number"
                />
              </div>
              <div className="">
                <h6 className="font-bold text-sm md:text-base">Street Name</h6>
                <Field
                  name="street_name"
                  as={TextField}
                  className="admin_input_1 block_input"
                  placeholder="Street Name"
                />
              </div>
              <div className="">
                <h6 className="font-bold text-sm md:text-base">
                  Your Pickup Location
                </h6>
                <Field
                  name="pickup_location"
                  as={TextField}
                  className="admin_input_1 block_input"
                  placeholder="Pickup Location"
                />
              </div>

              <div className="flex flex-col gap-3 md:gap-4 md:col-span-2">
                <h6 className="font-bold text-sm md:text-base">
                  Closest Landmark
                </h6>
                <Field
                  name="closest_landmark"
                  as={TextField}
                  className="admin_input_1 block_input admin_textarea"
                  placeholder="Closest Landmark"
                  multiline
                  rows={6}
                />
              </div>
            </div>

            {!uuid && (
              <>
                <div className="dashboard_subtitle mt-6 md:mt-4">
                  Profile Photo
                </div>

                <div>
                  <div
                    className="lg:w-[60%] border border-dashed border-gray-400 hover:border-black rounded-lg p-10"
                    onDragOver={(event) => event.preventDefault()}
                    onDrop={handleDrop}
                  >
                    <label
                      htmlFor="image-upload"
                      className="cursor-pointer flex flex-col items-center justify-center"
                    >
                      <img src={imageUploadIcon} alt="User" className="w-6" />
                      <p className="text-black text-center text-sm mt-3">
                        Drag your image here
                      </p>
                    </label>
                    <input
                      id="image-upload"
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={handleImageUpload}
                    />
                  </div>
                  <div className="border mt-5 border-dashed border-[#BEBEBE] w-fit rounded-[27px] p-3">
                    {profileImageLoading ? (
                      <Skeleton variant="circular" width={120} height={120} />
                    ) : (
                      <img
                        src={profileImage || dummyUserImage}
                        alt="User"
                        className="rounded-full h-[120px] w-[120px]"
                      />
                    )}
                  </div>
                </div>
              </>
            )}

            <div className="mt-5 w-full max-w-[300px] flex flex-col">
              <BlackButton
                text="Submit"
                type="submit"
                isDisabled={!isValid}
                isLoading={isSubmitting}
              ></BlackButton>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
};
