import React from "react";

const Policies = () => {
  return (
    <section>
      <main className="about_privacy ">
        <div className="about_layout_privacy lg:mt-[100px]">
          <h2 className="web_heading bar_above">Policies</h2>
          <div className="web_paragraph_1 mt-3 text-">
            <div className="py-3">
              <p>
                <strong>Purchased items are final sale.</strong>
              </p>
              <p>
                As the customer, you are fully responsible for any material
                which you compose via our gift messaging and personalization
                services. You guarantee that any messages and/or text posted on
                the website to be sent to a third party, by us, on your behalf,
                will not contain any obscene, vulgar, rude, racist, threatening,
                or defamatory language which may cause any distress to any
                person or be unlawful and does not infringe on a third party
                trademark or copyright.
              </p>
              <p className="py-3">
                We respect the intellectual property rights of others and we ask
                that you do the same. All text are reviewed upon submission and
                if your submission infringes any copyrights or trademarks, your
                order will be cancelled and refunded.
              </p>
              <p>
                Faith Grey may print but does not have any political
                affiliations or a member of political associations. Faith Grey
                does not endorse any particular affiliation or association. We
                have the sole discretion to reject any order that we consider is
                libelous, defamatory, profane, obscene, portraying irresponsible
                use of alcohol or other substances, advocating persecution based
                on gender, age, race, religion, disability or national origin,
                containing explicit sexual content or otherwise inappropriate
                for production.
              </p>
            </div>

            <div className="py-3">
              <p>
                <strong>Policy Regarding Use of a Forwarding Address</strong>
              </p>
              <p>
                We do not support the use of third-party forwarders for various
                reasons including, but not limited to the loss, damage, or
                manipulation of our products and/or packaging. Faith Grey’s
                warranty does not cover any products that have been reported
                damaged or lost if the shipping address on file is associated
                with freight forwarding, international forwarding, or the
                redirecting of packages.
              </p>
              <p>
                Faith Grey reserves the right to cancel and refund any order
                shipping to any address associated with freight forwarding,
                international forwarding, or the redirecting of packages. Should
                Faith Grey cancel and refund your order, Faith Grey will not
                accept responsibility for any fees or loss of funds due to
                issues such as international exchange rate or banking processes.
              </p>
              <p>
                Faith Grey is not liable for any charges or additional charges
                incurred by you from any third-party shipping company for any
                undeliverable, rerouted, or returned packages.
              </p>
            </div>
          </div>
        </div>
      </main>
    </section>
  );
};

export default Policies;
