import { useState } from "react";
import GhanaFlag from "../assets/ghana-flag.png";
import NigeriaFlag from "../assets/nigeria-flag.png";
import SAFlag from "../assets/sa-flag.png";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import { FaChevronDown } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { IAppState } from "../store/store";
import { FiUser } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { logout } from "../store/authentication/actions/actions";
import { setGhs, setNaira, setZar } from "../store/shared/actions";

export const NavbarFlag = () => {
  const currentFlag = useSelector((state: IAppState) => state.shared.currency);
  const dispatch = useDispatch();

  return (
    <div className="flex items-center gap-2 relative shrink-0">
      <Menu
        portal
        className="nav_flag_menu"
        direction="bottom"
        align="start"
        menuButton={
          <MenuButton className="flex items-center gap-2">
            {(() => {
              if (currentFlag === "GHS") {
                return <img src={GhanaFlag} alt="Ghana flag" className="w-5 sm:w-7" />;
              } else if (currentFlag === "NGN") {
                return <img src={NigeriaFlag} alt="Nigerian flag" className="w-5 sm:w-7" />;
              } else if (currentFlag === "ZAR") { 
                return <img src={SAFlag} alt="South African flag" className="w-5 sm:w-7" />;
              }
            })()}
            <FaChevronDown size={14} />
          </MenuButton>
        }
      >
         <MenuItem>
          <img
            onClick={() => dispatch(setZar())}
            src={SAFlag}
            alt="South African flag"
            className="w-5 sm:w-7 cursor-pointer"
          />
        </MenuItem>
        <MenuItem>
          <img
            onClick={() => dispatch(setGhs())}
            src={GhanaFlag}
            alt="Ghana flag"
            className="w-5 sm:w-7 cursor-pointer"
          />
        </MenuItem>
        <MenuItem>
          <img
            onClick={() => dispatch(setNaira())}
            src={NigeriaFlag}
            alt="Nigerian flag"
            className="w-5 sm:w-7 cursor-pointer"
          />
        </MenuItem>
       
      </Menu>
    </div>
  );
};

export const NavbarUserIcon = () => {
  const token = useSelector((state: IAppState) => state.auth.token);
  const dispatch = useDispatch();

  return token ? (
    <Menu
      portal
      className="account_logout_menu"
      direction="bottom"
      align="center"
      offsetY={16}
      menuButton={
        <MenuButton className="rounded-full shadow-md w-8 h-8 flex justify-center items-center">
          <FiUser size={18} />
        </MenuButton>
      }
    >
      <MenuItem>
        <NavLink to="/account/profile">
          <div>Account</div>
        </NavLink>
      </MenuItem>
      <MenuItem onClick={() => dispatch(logout())}>
        <div>Logout</div>
      </MenuItem>
    </Menu>
  ) : null;
};
