import { FaRegBell } from "react-icons/fa";
import { NavLink, Outlet } from "react-router-dom";
import whiteLogo from "../assets/logo-white.png";
import dummyUserImage from "../assets/dummy/dummy-user.png";
import settingsIcon from "../assets/icons/settings-icon.svg";
import campaignsIcon from "../assets/icons/white-hamburger.svg";
import dashboardIcon from "../assets/icons/dashboard-icon.svg";
import logoutIcon from "../assets/icons/log-out.svg";
import { useEffect, useRef } from "react";
import hamburger from "../assets/icons/black-hamburger.svg";
import {
  closeSidebar_admin,
  toggleSidebar_admin,
} from "../store/shared/actions";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../store/store";

export const AdminLayout = () => {
  const navOpen = useSelector((state: IAppState) => state.shared.navOpenAdmin);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    document.addEventListener("click", closeSideBar_, true);
    return () => {
      document.removeEventListener("click", closeSideBar_, true);
    };
  }, []);

  const closeSideBar_ = (e: MouseEvent) => {
    if (
      sidebarRef.current?.contains(e.target as Node) ||
      (e.target instanceof Element &&
        e.target.classList.contains("exempt_sidebar_close"))
    ) {
      // The click occurred within the sidebar or on an exempt element, so do nothing.
      return;
    }
    //   close sidebar
    dispatch(closeSidebar_admin());
  };

  return (
    <>
      {/* nav area */}
      <div
        ref={sidebarRef}
        className={`fixed bg-black shadow w-[250px] z-50 flex flex-col justify-between h-screen overflow-y-auto duration-700 ${
          navOpen ? "translate-x-0" : "translate-x-[-1000px]"
        } lg:!translate-x-0`}
      >
        <div className="p-6 pl-10">
          <img src={whiteLogo} className="w-[140px]" alt="Logo" />
        </div>
        <nav className="mt-8 font-montserrat text-white">
          <NavLink
            onClick={() => dispatch(closeSidebar_admin())}
            end
            to="/admin"
            className="flex items-center gap-3 py-2 pl-10 2xl:px-12 pr-6 admin_nav"
          >
            <img src={dashboardIcon} className="w-6" />
            <span className="text-lg">Dashboard</span>
          </NavLink>
          <NavLink
            onClick={() => dispatch(closeSidebar_admin())}
            to="/admin/campaigns"
            className="flex items-center gap-3 py-2 pl-10 2xl:px-12 pr-6 admin_nav"
          >
            <img src={campaignsIcon} className="w-6" />
            <span className="text-lg">Campaigns</span>
          </NavLink>
          <NavLink
            onClick={() => dispatch(closeSidebar_admin())}
            to="/admin/settings"
            className="flex items-center gap-3 py-2 pl-10 2xl:px-12 pr-6 admin_nav"
          >
            <img src={settingsIcon} className="w-6" />
            <span className="text-lg">Settings</span>
          </NavLink>
        </nav>
        <div className="flex-grow"></div>
        <div className="font-montserrat text-white">
          <NavLink
            to="/"
            className="mt-auto mb-8 flex items-center gap-3 py-2 pl-10 2xl:px-12 pr-6 admin_nav"
          >
            <img src={logoutIcon} className="w-6" />
            <span className="text-lg">Logout</span>
          </NavLink>
        </div>
      </div>

      <div className="lg:w-[calc(100vw-250px)] lg:ml-auto">
        <nav className="bg-white py-4 sticky top-0 z-10">
          <div className="px-4 sm:px-6 lg:px-8 2xl:px-14 flex gap-5 justify-end items-center">
            <div className="flex-1 lg:hidden">
              <img
                onClick={() => dispatch(toggleSidebar_admin())}
                src={hamburger}
                alt="hamburger-icon"
                className="exempt_sidebar_close w-[18px] cursor-pointer"
              />
            </div>
            <div>
              <FaRegBell className="text-black text-2xl" />
            </div>
            <div>
              <img
                src={dummyUserImage}
                alt="User"
                className="rounded-full h-8 w-8 lg:w-14 lg:h-14"
              />
            </div>
          </div>
        </nav>

        {/* moved the min height here */}
        <div className="min-h-[calc(100vh-90px)] px-4 sm:px-6 lg:px-8 2xl:px-14 py-8 md:py-10 tall:py-[72px] 2xl:py-[72px] bg-[#F6F6F6]">
          <Outlet />
        </div>
      </div>
    </>
  );
};
