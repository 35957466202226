import { CurrencyEnum } from "../../models/currency";
import {
  CLOSE_SIDEBAR,
  CLOSE_SIDEBAR_ADMIN,
  SET_GHS,
  SET_NAIRA,
  SET_ZAR,
  TOGGLE_SIDEBAR,
  TOGGLE_SIDEBAR_ADMIN,
} from "./actions";

export interface ISharedState {
  navOpen: boolean;
  navOpenAdmin: boolean;
  currency: CurrencyEnum;
  hasSwitchedCurrency: boolean;
}

const initialState: ISharedState = {
  navOpen: false,
  navOpenAdmin: false,
  currency: (localStorage.getItem("currency") as any) ?? "ZAR",
  hasSwitchedCurrency: false,
};

export const sharedReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        navOpen: !state.navOpen,
      };
    case CLOSE_SIDEBAR:
      return {
        ...state,
        navOpen: false,
      };
    case TOGGLE_SIDEBAR_ADMIN:
      return {
        ...state,
        navOpenAdmin: !state.navOpenAdmin,
      };
    case CLOSE_SIDEBAR_ADMIN:
      return {
        ...state,
        navOpenAdmin: false,
      };
    case SET_GHS:
      localStorage.setItem("currency", "GHS");
      return {
        ...state,
        currency: "GHS",
        hasSwitchedCurrency: true,
      };
    case SET_NAIRA:
      localStorage.setItem("currency", "NGN");
      return {
        ...state,
        currency: "NGN",
        hasSwitchedCurrency: true,
      };
    case SET_ZAR:
      localStorage.setItem("currency", "ZAR");
      return {
        ...state,
        currency: "ZAR",
        hasSwitchedCurrency: true,
      };
    default:
      return state;
  }
};
