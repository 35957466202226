import React from "react";

interface ButtonProps {
  text: string;
  className?: string;
  icon?: React.ReactNode;
  iconPosition?: "start" | "end";
}

export const OutlineButton = ({
  className,
  icon,
  iconPosition,
  text,
}: ButtonProps) => {
  return (
    <button
      className={`${
        className +
        " px-6 py-2 rounded-[24px] bg-white text-black border-2 border-solid border-black focus:outline-none focus:shadow-outline transform transition duration-300 perspective-origin-center hover:-rotate-y-6 hover:scale-[1.02] active:rotate-y-180 active:scale-100"
        + `${icon? ' !flex items-center gap-2': ''}`
      }`}
    >
        {text}
        {icon}
    </button>
  );
};
